import { Component } from '@angular/core';

@Component({
  selector: 'app-account-order',
  standalone: true,
  imports: [],
  templateUrl: './account-order.component.html',
  styleUrl: './account-order.component.scss'
})
export class AccountOrderComponent {

}
