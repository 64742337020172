import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication-service/authentication.service";

export const authGuard: CanActivateFn = (route, state) => {
   const authService = inject(AuthenticationService);
   const router: Router = inject(Router);
   console.log(authService.isAuthenticated())
   if(!authService.isAuthenticated()) router.navigate(['user','signin']);
   return authService.isAuthenticated();
}
