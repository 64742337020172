import { Component } from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {BaseComponentService} from "./shared/base-component/base-component.service";
import {LoaderComponent} from "./components";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponentService{

   ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.auth.autoSignIn();
    }
  }
}
