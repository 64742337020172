import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {Collection, ProductModel} from "../../shared/model";
import {Collection_Homepage} from "../../shared/model/products-filter.model";
import {JsonPipe} from "@angular/common";
import {RemoveHtmlPipe} from "../../shared/Pipe/remove-html.pipe";

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    RouterLink,
    RemoveHtmlPipe
  ],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})
export class ProductComponent extends BaseComponentService{
 @Input('collection') collection: Collection | null = null;
   ngOnInit() {
  }



}
