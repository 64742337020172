export class DataIndex<T = any>{
   public dict: Record<string | number, T>;
   public list: T[];
    constructor(data: T[] = [], keyName:string = 'id') {
      this.list = data;
      this.dict =  this.mapDict(data, keyName);
    }

    private mapDict(data: T[], keyName: string) {
      let obj: Record<keyof T | any, T> = {};
      if(keyName.includes('.'))
      data.forEach((item) => {
        let key;
        if(keyName.includes('.')) {
         const func = () => {
          const nestedKey = keyName.split('.');
          let value:any = item;
          nestedKey.forEach(i => value = value[i as keyof T] || null);
          return value;
          };
          key = func();
        } else {
          key = item[keyName as keyof T];
        }
        obj[key as keyof T] = item;
      })
      return obj;
    }
}
