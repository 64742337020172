import { Component } from '@angular/core';

@Component({
  selector: 'app-account-return',
  standalone: true,
  imports: [],
  templateUrl: './account-return.component.html',
  styleUrl: './account-return.component.scss'
})
export class AccountReturnComponent {

}
