import {Component, inject} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../shared/model";
import {getDate} from "../../shared/constants/date-picker.constants";
import {RegisterComponent} from "../register/register.component";
import {ValidationMessageComponent} from "../validation-message/validation-message.component";

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ValidationMessageComponent
  ],
  templateUrl: './account-details.component.html',
  styleUrl: './account-details.component.scss'
})
export class AccountDetailsComponent extends RegisterComponent{

  constructor() {
    super();
    const userDetail = this.auth.user();
    const splitUsername = userDetail?.name.split(' ') || [];
    // const date = ""
    // console.log(userDetail?.)
    this.registerForm.patchValue({
      first_name: splitUsername[0] || '',
      last_name: splitUsername[1] || '',
      email: userDetail?.email,
      // interest: userDetail?.
    });
    // this.getUser();
  }


  // private getUser() {
  //   this.subscriptionArray = this.request
  //     .getRequest(``).subscribe({
  //       next: (res) => {
  //         console.log(res)
  //       }
  //     })
  // }
}
