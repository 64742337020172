import {inject, Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiConfiguration} from "../../model/api-configuration.type";
import {catchError, Observable, of} from "rxjs";
import {ErrorModel} from "../../model/error-model.type";
import {ResponseType} from "../../model";
import {LoaderService} from "../loader-service/loader.service";
import {AuthenticationService} from "../authentication-service/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  private baseUrl: string = `${environment.BASE_URL}/${environment.PUBLIC_API_VERSION}`;
  public secondBaseUrl: string = `${environment.SECOND_BASE_URL}/${environment.PUBLIC_API_VERSION}`;
  private httpClient: HttpClient = inject(HttpClient);
  private loaderService: LoaderService = inject(LoaderService);
  private auth: AuthenticationService = inject(AuthenticationService);
  public defaultConfiguration: ApiConfiguration = {
    auth: true,
    globalErrorHandle: true
  }

  constructor() {
  }

  public getBaseApi() {
    return this.baseUrl;
  }

  private getBaseApiWithCondition(url:string, configuration: ApiConfiguration) {
    return configuration.domain ? `${configuration.domain}/${url}` : `${this.baseUrl}/${url}`;
  }

  private getToken() {
    return this.auth.token();
  }
  private getHttpOption(configuration?: ApiConfiguration) {
    if(!(configuration?.auth || configuration?.contentType ||configuration?.Headers)) return {};
    let option: any = {
      headers: new HttpHeaders()
    };
    if(configuration?.auth) option.headers.set('Authorization', this.getToken());
    if(configuration?.contentType) option = {...option,responseType: option.responseType = configuration.contentType};
    return option;
  }

  public postRequest<T = any, R = any>(api: string, payload: T, configuration: ApiConfiguration = this.defaultConfiguration): Observable<ResponseType<R>> {
    return this.httpClient
      .post<ResponseType<R>>(this.getBaseApiWithCondition(api,configuration), payload)
      .pipe(
        catchError(this.handleError(configuration))
      )
  }

  public getRequest<R = any>(api: string, configuration: ApiConfiguration = this.defaultConfiguration) {
    return this.httpClient
      .get<ResponseType<R>>(this.getBaseApiWithCondition(api,configuration), this.getHttpOption() as {})
      .pipe(
        catchError(this.handleError(configuration))
      )
  }

  public patchRequest<T = any, R = any>(api: string, payload: T, configuration: ApiConfiguration = this.defaultConfiguration) {
    return this.httpClient
      .patch<ResponseType<R>>(this.getBaseApiWithCondition(api,configuration), payload, this.getHttpOption())
      .pipe(
        catchError(this.handleError(configuration))
      )
  }

  public deleteRequest<T = any, R = any>(api: string, payload: T, configuration: ApiConfiguration = this.defaultConfiguration) {
    return this.httpClient
      .delete<ResponseType<R>>(this.getBaseApiWithCondition(api,configuration), this.getHttpOption())
      .pipe(
        catchError(this.handleError(configuration))
      )
  }

  public handleError(configuration: ApiConfiguration) {
    this.loaderService.hide();
     return (error: ErrorModel) => {
       console.log(error,"[][][][")
       if(configuration?.globalErrorHandle) {
         this.globalErrorLog(error)
         throw of();
       }
       else {
         throw error;
       }
     }
  }


  public globalErrorLog(error: ErrorModel) {
    let errorModel = {
      message: "",
      statue: "",
      mode: ""
    }
    switch(error) {

    }
  }
}
