<div class="item">
  <div class="img__holder">
    <img [src]="product?.images?.thumbnail" #img
         (error)="img.src = 'assets/images/noimage_v2.png'" alt="Product Image" />
  </div>
  <div class="content">
    <h5 class="title">{{product?.name | titlecase}}</h5>
    <p class="info">{{product?.description | removeHtml}}</p>
  </div>
  <button (click)="navigate(product?.id || null)"  class="shop__btn">Shop now</button>
  <a [routerLink]="['/','detail',product?.id]"></a>
</div>
