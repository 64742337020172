import { Component } from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss'
})
export class CartComponent extends BaseComponentService{

   ngOnInit() {
  }

}
