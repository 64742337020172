<div class="checkout-container">
  <div class="delivery-message">
    <p>Can we tempt you? Spend another £188.01 to qualify for FREE Standard Delivery to Nepal.</p>
  </div>
  <div class="container">
    <div class="checkout-details">
      <div class="checkout">
        <div class="checkout-head">
          <h2 class="title">MY BAG</h2>
          <span>Items are reserved for 60 minutes</span>
        </div>
        <div class="checkout-body">
          <!-- Item 1 -->
          <div class="item">
            <div class="product-img">
              <img src="assets/media/product-img.png" alt="Product Image" />
            </div>
            <div class="product-content">
              <span class="price">£16.99</span>
              <p class="info">New Look muscle fit poplin shirt in black</p>
              <div class="category">
                <span>BLACK | S</span>
                <span><span class="qty">Qty</span> <span>1</span></span>
              </div>
              <button>❤️ Save for later</button>
            </div>
            <div class="btn">
              <button>❌</button>
            </div>
          </div>
          <!-- Item 2 -->
          <div class="item">
            <div class="product-img">
              <img src="assets/media/product-img.png" alt="Product Image" />
            </div>
            <div class="product-content">
              <span class="price">£16.99</span>
              <p class="info">New Look muscle fit poplin shirt in black</p>
              <div class="category">
                <span>BLACK | S</span>
                <span><span class="qty">Qty</span> <span>1</span></span>
              </div>
              <button>❤️ Save for later</button>
            </div>
            <div class="btn">
              <button>❌</button>
            </div>
          </div>
          <!-- Item 3 -->
          <div class="item">
            <div class="product-img">
              <img src="assets/media/product-img.png" alt="Product Image" />
            </div>
            <div class="product-content">
              <span class="price">£16.99</span>
              <p class="info">New Look muscle fit poplin shirt in black</p>
              <div class="category">
                <span>BLACK | S</span>
                <span><span class="qty">Qty</span> <span>1</span></span>
              </div>
              <button>❤️ Save for later</button>
            </div>
            <div class="btn">
              <button>❌</button>
            </div>
          </div>
        </div>
        <div class="checkout-tail">
          <h2 class="sub-title">Sub-total</h2>
          <span>£16.99</span>
        </div>
        <div class="extra">
          A little something extra?
        </div>
        <div class="delivery">
          <div class="icon-holder">
            <img src="assets/icons/truck.svg" width="32" height="32" alt="Truck" />
          </div>
          <div class="content">
            <h3>FREE* STANDARD DELIVERY</h3>
            <p>Faster delivery options available to most countries.</p>
            <a href="#">More info</a>
          </div>
        </div>
      </div>
      <div class="cart-container">
        <h2 class="title">Total</h2>
        <div class="total">
          <div class="price">
            <h3 class="sub-title">Sub-total</h3>
            <span>£16.99</span>
          </div>
          <div class="delivery">
            <h3 class="sub-title">Delivery</h3>
            <select>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
              <option value="">Standard Delivery (£10.00)</option>
            </select>
          </div>
        </div>
        <button>CHECKOUT</button>
        <div class="accept">
          <h2 class="title">WE ACCEPT:</h2>
          <div class="cards">
            <span>Icon</span>
            <span>Icon</span>
            <span>Icon</span>
            <span>Icon</span>
          </div>
          <p>Got a discount code? Add it in the next step.</p>
        </div>
      </div>
    </div>
    <div class="saved-info">
      <div class="content">
        <h2>Wondering where your items have gone?</h2>
        <hr />
        <p>No worries – you'll find them in your Saved Items.</p>
        <a href="#">VIEW ALL SAVED ITEMS</a>
      </div>
    </div>
  </div>
</div>
