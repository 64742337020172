<form class="form" [formGroup]="loginForm">
  <div class="input__box">
    <label>Email Address</label>
    <input formControlName="email" name="email"  type="text" />
    <app-validation-message [controlForError]="loginForm.controls.email" [submitted]="submitFlag" />
  </div>
  <div class="input__box">
    <label>Password</label>
    <input formControlName="password" name="password" type="password" />
    <app-validation-message [controlForError]="loginForm.controls.password" [submitted]="submitFlag" />

  </div>
  <input type="submit" (click)="login()" value="SIGN IN" />
  <a routerLink="/forget" class="forgot">Forgot password?</a>
</form>
<div class="options">
  <h4 class="title">Or sign in with...</h4>
             <app-signin-option />
  <!-- Placeholder for SignInOption Component -->
  <p class="twitter">Where has Twitter Gone?</p>
</div>
