<!-- <div class="banner">
  <div class="container-sm">
    <div class="banner__container">
      <h4 class="title">
        20% OFF ALMOST EVERYTHING!*
      </h4>
      <div class="content">
        <p>Time for a Boxing Day scroll...</p>
        <p>With code: <span>TAKE20</span></p>
      </div>
      <span class="condition">*Minimum spend of £20 applies. See website banner for full Ts&Cs. Selected marked products excluded from promo.</span>
    </div>
  </div>
</div> -->


<div class="banner__new">
  <img src="../../../assets/media/banner__new-new.jpg" alt="">
</div>
