import {Component, CUSTOM_ELEMENTS_SCHEMA, inject} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ApiConstant} from "../../shared/constants";
import {environment} from "../../../environments/environment";
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthService,
  SocialLoginModule, SocialUser
} from "@abacritt/angularx-social-login";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Component({
  selector: 'app-signin-option',
  standalone: true,
  imports: [
    SocialLoginModule,
    GoogleSigninButtonModule
  ],
  templateUrl: './signin-option.component.html',
  styleUrl: './signin-option.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SigninOptionComponent extends BaseComponentService{
  private googleService: SocialAuthService = inject(SocialAuthService);
  override ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      this.listenToLogin();
    }
  }

  protected listenToLogin(){
    this.subscriptionArray = this.googleService.authState.subscribe({
      next: (res) => {
        this.signIn(res);
      },
      error: err => {
      }
    })
  }


  protected google() {
    this.googleService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  protected signIn(res: SocialUser) {
    this.subscriptionArray = this.request
      .postRequest(`${ApiConstant.AUTH}/${ApiConstant.GOOGLE}/${ApiConstant.LOGIN}`, {
      token: res.idToken
    }, {
        ...this.request.defaultConfiguration,
        domain: this.request.secondBaseUrl
      })
      .subscribe({
        next: (res) => {
          this.auth.signIn(res.data.token, res.data.payload);
          this.router.navigate(['account']);
        }
      })

  }
}
