<footer class="footer">
  <!-- <div class="upper__footer">
    <div class="container">
      <div class="upper__footer_container">

        <div class="list">
          <h5 class="title">Help & Information</h5>
          <ul>
            <li><a href="#">Help</a></li>
            <li><a href="#">Track order</a></li>
            <li><a href="#">Delivery & returns</a></li>
            <li><a href="#">CELAVI Premier</a></li>
            <li><a href="#">10% Student Discount</a></li>
            <li><a href="#">Sitemap</a></li>
          </ul>
        </div>
        <div class="list">
          <h5 class="title">About CELAVI</h5>
          <ul>
            <li><a href="#">About us</a></li>
            <li><a href="#">Careers at CELAVI</a></li>
            <li><a href="#">Corporate responsibility</a></li>
            <li><a href="#">Investors' site</a></li>
            <li><a href="#">Cyber Security</a></li>
          </ul>
        </div>
        <div class="list">
          <h5 class="title">More From CELAVI</h5>
          <ul>
            <li><a href="#">Mobile and CELAVI apps</a></li>
            <li><a href="#">CELAVI Marketplace</a></li>
            <li><a href="#">Gift vouchers</a></li>
            <li><a href="#">Black Friday</a></li>
            <li><a href="#">CELAVI x Thrift+</a></li>
            <li><a href="#">Refer A Friend</a></li>
            <li><a href="#">Info for Partners</a></li>
            <li><a href="#">Discover the CELAVI Credit Card</a></li>
          </ul>
        </div>
        <div class="list">
          <h5 class="title">Help & Information</h5>
          <ul>
            <li><a href="#">Help</a></li>
            <li><a href="#">Track order</a></li>
            <li><a href="#">Delivery & returns</a></li>
            <li><a href="#">CELAVI Premier</a></li>
            <li><a href="#">10% Student Discount</a></li>
            <li><a href="#">Sitemap</a></li>
          </ul>
        </div>

      </div>
    </div>
  </div> -->
  <div class="lower__footer">
    <div class="container">
      <div class="lower__footer_container">
        <p>© 2024 Aura Fashion Nepal</p>
        <ul>
          <li><a href="https://www.instagram.com/aurafashionnepal__/" target="_blank"><i class="icon-instagram-solid"></i></a></li>
          <li><a href="https://www.instagram.com/aurafashionnepal__/" target="_blank"><i class="icon-fb-solid"></i></a></li>
          <!-- <li><a href="#">Ts&Cs</a></li>
          <li><a href="#">Accessibility</a></li> -->
        </ul>
      </div>
    </div>
  </div>
</footer>

