import { Component } from '@angular/core';
import {FooterComponent, TopNavbarComponent} from "../../components";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    TopNavbarComponent,
    FooterComponent,
    RouterOutlet
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {

}
