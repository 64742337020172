import {Component, Input} from '@angular/core';

interface VideoPlayerData {
  thumbnail: string;
  videoSrc: string;
}

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss'
})
export class VideoPlayerComponent {
  @Input('VideoPlayerData') VideoPlayerData?: VideoPlayerData;

}
