<div class="options">
        <h4 class="title">sign up with...</h4>
             <app-signin-option />
        <p class="info__signup">
          Signing up with social is super quick. No extra passwords to remember - no brain fail. Don't worry, we'd never share any of your data or post anything on your behalf <br /> #NotEvil
        </p>
      </div>
<h4 class="title">Or sign up with email</h4>
<form class="form" [formGroup]="registerForm">
        <div class="input__box">
          <label>Email Address</label>
          <input formControlName="email" type="text" />
          <span>We'll send your order confirmation here</span>
          <app-validation-message [controlForError]="registerForm.controls.email" [submitted]="submitFlag" />
        </div>
        <div class="input__box">
          <label>First Name</label>
          <input formControlName="first_name" type="text" />
          <app-validation-message [controlForError]="registerForm.controls.first_name" [submitted]="submitFlag" />

        </div>
        <div class="input__box">
          <label>Last Name</label>
          <input formControlName="last_name" type="text" />
          <app-validation-message [controlForError]="registerForm.controls.last_name" [submitted]="submitFlag" />
        </div>
        <div class="input__box">
          <label>Password</label>
          <input formControlName="password" type="password" />
          <app-validation-message [controlForError]="registerForm.controls.password" [submitted]="submitFlag" />

        </div>
        <div class="input__box" formGroupName="dob">
          <label>Date of birth:</label>
          <div class="birthday">
            <div class="custom__select">
              <select formControlName="day">
                <option value="0">DD</option>
                @for (day of date.day(this.getRawValue.dob.year || '2023', this.getRawValue.dob.month || '1');track day;let i = $index) {
                  <option [value]="day">{{day}}</option>
                }

              </select>
            </div>
            <div class="custom__select">
              <select formControlName="month">
                <option value="">Month</option>
                @for (mon of date.month;track mon;let i = $index) {
                  <option [value]="i + 1">{{mon}}</option>
                }
              </select>
            </div>
            <div class="custom__select">
              <select formControlName="year">
                <option value="">YYYY</option>
                @for (ye of date.year;track ye;let i = $index) {
                  <option [value]="ye">{{ye}}</option>
                }
              </select>
            </div>
          </div>
          <span>You need to be 16 or over to use CELAVI</span>
        </div>
        <div class="input__box">
          <label>MOSTLY INTERESTED IN:</label>
          <div class="radio">
            <div class="radio__btn">
              <input formControlName="interest" type="radio" id="womenswear" name="interest" value="Womenswear" />
              <label for="womenswear">Womenswear</label>
            </div>
            <div class="radio__btn">
              <input formControlName="interest" type="radio" id="menswear" name="interest" value="Menswear" />
              <label for="menswear">Menswear</label>
            </div>
            <app-validation-message [controlForError]="registerForm.controls.interest" [submitted]="submitFlag" />
          </div>
        </div>
        <div class="input__box">
          <div class="item__container">
            <div class="item">
              <h3>Contact Preferences</h3>
              <p>Tell us which emails you’d like:</p>
            </div>
            <div class="item">
              <button type="button">SELECT ALL</button>
            </div>
          </div>
          <div class="item__container">
            <div class="item">
              <h3>Discounts and new drops</h3>
            </div>
            <div class="item">
              <input type="checkbox" />
            </div>
          </div>
        </div>
        <input (click)="submit()" type="submit" value="JOIN CELAVI" />
      </form>

