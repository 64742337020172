import {Component, computed, inject, makeStateKey, StateKey} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {CommonModule, isPlatformBrowser, isPlatformServer} from "@angular/common";
import {RouterLink} from "@angular/router";
import {ApiConstant, COLLECTION} from "../../shared/constants";
import {CollectionService} from "../../shared/services/component-service/collection/collection.service";
import {Collection} from "../../shared/model";
import {DataIndex} from "../../shared/class/data-index.class";
import {FormsModule} from "@angular/forms";
import {OutsideClickDirective} from "../../shared/Directive/outside-click.directive";


@Component({
  selector: 'app-top-navbar',
  standalone: true,
  imports: [CommonModule, RouterLink, FormsModule, OutsideClickDirective],
  templateUrl: './top-navbar.component.html',
  styleUrl: './top-navbar.component.scss'
})
export class TopNavbarComponent extends BaseComponentService{
   protected collectionConst = COLLECTION;
   protected femaleFlag = computed(() => {
       if(this.globalValue.navOption()) {
         this.subTwoNavigationList = new DataIndex(this.subOneNavigationList.dict[this.globalValue.navOption()]?.children || []);
       }
       return this.globalValue.navOption() === COLLECTION.women;
   });
   public collectionService: CollectionService = inject(CollectionService);
   public collectionTreeKeyState: StateKey<Collection[]> = makeStateKey('collection-tree');
   public collectionTree: Collection[] = [];
   public subOneNavigationList: DataIndex = new DataIndex<Collection>();
   public subTwoNavigationList: DataIndex = new DataIndex<Collection>();

   protected dropdown = {
     subMenu: false,
     barMenu: false
   }



   ngOnInit() {
     if(isPlatformServer(this.platformId)){
       this.getAllCollectionList();
     } else if(isPlatformBrowser(this.platformId)) {
       this.parseCollectionData(this.transferState.get(this.collectionTreeKeyState,null));
     }

  }

  private getAllCollectionList() {
     this.subscriptionArray = this.request
       .getRequest<Collection[]>(ApiConstant.COLLECTION,
         {...this.request.defaultConfiguration,domain: this.request.secondBaseUrl,auth:false})
       .subscribe({
         next: (res) => {
           this.transferState.set(this.collectionTreeKeyState, res.data);
         }
       })
  }

  private parseCollectionData(res: Collection[] | null) {
     if(!res) return;
    this.collectionTree = this.collectionService.buildCollectionTree(res);
    this.subOneNavigationList = new DataIndex<Collection>(this.collectionTree, 'attribute_data.name');
    const flattenCollectionTree = this.collectionService.flattenCollectionTree(this.collectionTree);
  }

}

