<!--<app-bread-crumb />-->
<div class="productDetail__container">
  <div class="container">
    <div class="producDetails">
      <div class="image__container">
        <div class="side__images">
          @for (media of products?.media || []; track media;let i = $index;) {
            <div (click)="swiper.swiper.slideTo(i)" class="item"><img [src]="media" alt="Product Image" /></div>
          }

        </div>
<!--        autoplay-delay="2500"-->
<!--        autoplay-disable-on-interaction="false"-->
<!--        loop="true"-->
<!--        -->
<!--        space-between="30" speed="500"-->
        <div class="main__image">
        <swiper-container #swiper slides-per-view="1"   space-between="30" loop="true" navigation="true">
          @for (media of products?.media; track media) {
            <swiper-slide>
              <img #mainImage [src]="media" alt="Product Image" />
              <i class="icon icon-heart"></i>
            </swiper-slide>
          }
        </swiper-container>
        </div>
<!--        <div class="main__image">-->
<!--          <img #mainImage [src]="(products?.media || [])[0]" alt="Product Image" />-->
<!--          <i class="icon icon-heart"></i>-->
<!--        </div>-->
      </div>
      <div class="details__container">
        <p class="short__description">{{products?.attribute_data?.['name']}}</p>
        <div class="price__container">
                  <span class="price">NPR {{ products?.price }}</span>
<!--                  <span class="discountend__price">Was £79.99 <span>(-29%)</span></span>-->
                </div>
<!--        <div class="price__container discounts">-->
<!--          <span class="price">Now NPR {{ products?.price }}</span>-->
<!--          <span class="discountend__price">Was £79.99 <span>(-29%)</span></span>-->
<!--        </div>-->
        <div class="classify">
        @for (option of productOption;track $index) {
          <div class="size">
            <div class="variant__title">
              <div><span class="title">{{option.name}}</span>: XL</div>
<!--              <button>Size Guide</button>-->
            </div>
            <select>
              <option value="">Please {{option.name}}</option>
              @for (optionValue of option.array;track $index){
                <option [value]="optionValue">{{optionValue}}</option>
              }
            </select>
          </div>
        }
        </div>

<!--        <div class="cart">-->
<!--          <button>ADD to BAG</button>-->
<!--          <span class="like"><i class="icon-heart"></i></span>-->
<!--        </div>-->
        <div class="delivery">
          <div class="content">
            <ul>
              <li><i class="icon icon-truck"></i> Free Delivery</li>
              <li><i class="icon icon-refund"></i> Free Returns</li>
              <li><a routerLink="policy">View our Delivery & Returns Policy</a></li>
            </ul>
          </div>
<!--          <div class="restiction">-->
<!--            <p><a href="#">This product has shipping restrictions.</a></p>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      <div class="product__info">
        @for (detail of productDetail; track $index) {
          <div class="item active" [ngClass]="{'active': detail.active}">
            <button class="button" type="button" (click)="openDetailDropdown(detail?.id)">
              <h2 class="header">{{detail.name | titlecase}}
                <div class="icon__wrapper">
                  <i class="icon icon-plus-solid"></i>
                  <i class="icon icon-minus-solid"></i>
                </div>

              </h2>
            </button>
            <div class="body" [innerHTML]="detail?.description">
<!--                <h3>Details</h3>-->
<!--                <p>  </p>-->
            </div>
          </div>
        }

      </div>
    </div>
  </div>
</div>

</div>
<div class="recommendation__container">
  <div class="container">
    <div class="container_sm">
      @if(upSellProduct.length > 0) {
        <h4 class="page__title">YOU MIGHT ALSO LIKE</h4>
        <div class="products__container">
          @for(pro of crossSellProduct;track $index){
            <div class="item">
              <div class="img__holder">
                <span class="like">
                  <i class="icons heart-regular"></i>
                  <i class="icons icon-heart"></i>
                </span>
                <img [src]="pro.product.images?.thumbnail || pro?.product?.thumbnail" alt="Product Image" />
              </div>
              <div class="content">
                <div class="price">
                  <span>NPR {{ pro.product.price }}</span>
                </div>
<!--                <div class="price discount">-->
<!--                  <span>£79.99 (-22%)</span> <span>£79.99</span>-->
<!--                </div>-->
                <p class="info">{{ pro.product.name | titlecase}}</p>
              </div>
              <a [routerLink]="['/','detail',pro.product.id]"></a>
            </div>
          }
          <!-- Repeat similar blocks for other products -->
        </div>
      }
      @if(crossSellProduct.length > 0) {
        <h4 class="page__title">People also bought</h4>
        <div class="products__container">
        @for(pro of upSellProduct;track $index){
          <div class="item">
            <div class="img__holder">
              <span class="like">
                <i class="icons heart-regular"></i>
                <i class="icons icon-heart"></i>
              </span>
              <img [src]="pro.product.images?.thumbnail || pro?.product?.thumbnail" alt="Product Image" />
            </div>
            <div class="content">
              <div class="price">
                <span>NPR {{ pro.product.price }}</span>
              </div>
              <!--                <div class="price discount">-->
              <!--                  <span>£79.99 (-22%)</span> <span>£79.99</span>-->
              <!--                </div>-->
              <p class="info">{{ pro.product.name | titlecase}}</p>
            </div>
            <a [routerLink]="['/','detail',pro.product.id]"></a>
          </div>
        }
          <!-- Repeat similar blocks for other products -->
        </div>
      }
    </div>
  </div>
</div>
