import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'listFilter',
  standalone: true
})
export class ListFilterPipe implements PipeTransform {
  /**
   * Filters list if search term matches name or code in object
   */
  transform(value: any, search: string, criteria: string): any {
    try {
      return value.filter((x: any) => x[criteria].toLowerCase().includes(search.toLowerCase().trim()));
    } catch(e) {
      return value;
    }
  }
}
