import { Component } from '@angular/core';

@Component({
  selector: 'app-account-password',
  standalone: true,
  imports: [],
  templateUrl: './account-password.component.html',
  styleUrl: './account-password.component.scss'
})
export class AccountPasswordComponent {

}
