<app-promo-banner/>
<app-video-player [VideoPlayerData]="videoPlayerData" />
<div class="container">
  <div class="productList__container">
    @for (i of  collectionList.dict[globalValue.navOption()]?.children || []; track i) {
    <app-product [collection]="i"
                 (click)="filterService.setValue('category', i.attribute_data.name)" />
    }
  </div>
</div>
<app-promo-banner-two />
<div class="container">
  <div class="productListCTA__container">
@for (i of  collectionList.dict[globalValue.navOption()]?.products; track i) {
<app-product-cta [product]="i" />

}
  </div>
</div>

<app-promo-banner-app />

<app-brands />
