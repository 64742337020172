<div class="my__details-container account__flex__container">
  <div class="head__component">
    <h2 class="title">My ORDERS</h2>
  </div>
  <p class="order__pagination">Displaying 10 of 13 orders</p>
  <div class="card">
    <div class="order__status">
      <p class="title">ORDER STATUS:</p>
      <p class="status">IT’S ORDERED!</p>
      <p class="info">Estimated delivery Saturday 9th September 2024</p>
    </div>
    <div class="items__container">
      <div class="item">
        <img src="../../../assets/media/item__placeholder.png" alt="">
      </div>
      <div class="item">
        <img src="../../../assets/media/item__placeholder.png" alt="">
      </div>
    </div>
    <hr>
    <div class="order__details">
      <div class="details">
        <p>ORDER NO.: <span>862682274</span></p>
        <p>ORDER DATE: <span>09 Sep, 2024</span></p>
      </div>
      <div class="buttons__container">
        <button>View Order</button>
        <button>Cancel order</button>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="order__status">
      <p class="title">ORDER STATUS:</p>
      <p class="status">IT’S DELIVERED!</p>
      <p class="info">Delivered Saturday 7th September 2024</p>
    </div>
    <div class="items__container">
      <div class="item">
        <img src="../../../assets/media/item__placeholder.png" alt="">
      </div>
      <div class="item">
        <img src="../../../assets/media/item__placeholder.png" alt="">
      </div>
    </div>
    <hr>
    <div class="order__details">
      <div class="details">
        <p>ORDER NO.: <span>862682274</span></p>
        <p>ORDER DATE: <span>09 Sep, 2024</span></p>
      </div>
      <div class="buttons__container">
        <button>View Order</button>
        <button>TRACK PARCEL</button>
      </div>
    </div>
  </div>
</div>
