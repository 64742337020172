import { Component } from '@angular/core';

@Component({
  selector: 'app-promo-banner-two',
  standalone: true,
  imports: [],
  templateUrl: './promo-banner-two.component.html',
  styleUrl: './promo-banner-two.component.scss'
})
export class PromoBannerTwoComponent {

}
