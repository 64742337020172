const Month =
  ['January','February','March','April','May', 'June','July','August','September','October','November','December'];

const year = function (){
  const start = 1900;
  const end = new Date().getFullYear()
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

const YEAR_ARRAY = year();

const date = function (){
}


export function getDaysInMonth(year: string, month: string) {
  // Create a date for the first day of the next month
  const nextMonth = new Date(Number(year), Number(month), 1);

  // Set the date to the last day of the current month
  nextMonth.setDate(0);

  // Return the last day of the current month
  return Array.from({ length: nextMonth.getDate() - 1 + 1 }, (_, i) => 1 + i);
}


// export const
export const getDate = {
  month: Month,
  day: getDaysInMonth,
  year: YEAR_ARRAY
}
