import {Component, inject} from '@angular/core';
import {SigninOptionComponent} from "../signin-option/signin-option.component";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../shared/model";
import {getDate} from "../../shared/constants/date-picker.constants";
import {ApiConstant} from "../../shared/constants";
import {ValidationMessageComponent} from "../validation-message/validation-message.component";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    SigninOptionComponent,
    ReactiveFormsModule,
    ValidationMessageComponent
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent extends BaseComponentService{
  protected registerForm: FormGroup<RegisterFormModel>;
  private formBuilder: FormBuilder = inject(FormBuilder);
  protected date = getDate;
  protected submitFlag: boolean = false;

  constructor() {
    super();
    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      dob: this.formBuilder.group({
        day: ["", Validators.required],
        month: ["", Validators.required],
        year: ["", Validators.required],
      }),
      interest: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

   ngOnInit() {
  }

  get getRawValue() {
    return this.registerForm.getRawValue();
  }

  protected submit() {
    this.submitFlag = true;
    if(!this.registerForm.valid) return;
    let payload = {
      ...this.getRawValue,
      password_confirmation: this.getRawValue.password,
      dob: new Date(Number(this.getRawValue.dob.year),Number(this.getRawValue.dob.month),Number(this.getRawValue.dob.day))
    };
    this.subscriptionArray = this.request.postRequest(`${ApiConstant.AUTH}${ApiConstant.REGISTER}`,payload)
      .subscribe({
        next: (res) => {
          if(res) {
            this.submitFlag = false;
            this.registerForm.reset();
            this.router.navigate(['user','signin']);
          }
        }
      })
  }
}

