<div class="signin__options">
  <asl-google-signin-button
    [disabled]="false"
    [type]="'standard'"
    [size]="'large'"
    (click)="google()">
  </asl-google-signin-button>

<!--  <a href="#" class="btn">-->
<!--    <img src="assets/icons/apple.svg" width="20" height="20" alt="Apple" />-->
<!--    <span>Apple</span>-->
<!--  </a>-->
<!--  <a href="#" class="btn">-->
<!--    <img src="assets/icons/fb.svg" width="20" height="20" alt="Facebook" />-->
<!--    <span>Facebook</span>-->
<!--  </a>-->
</div>
