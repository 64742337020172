
<main [style]="{'background-color':'#eee', 'min-height':'100vh'}">
<div class="user">
  <div class="container">
    <div class="user__container">
      <div class="buttons">
        <a routerLink="/user/signup" routerLinkActive="active">
          Join
        </a>
        <a routerLink="/user/signin" routerLinkActive="active">
          Sign in
        </a>
      </div>
      <router-outlet/>
    </div>
  </div>
</div>
<app-user-term />
</main>
