<div class="container">
  <h2 class="title">Sale</h2>
  <div class="sale__container">
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
    <div class="item">
      <a href="test"></a>
      <img src="/assets/images/product-img.png" alt="A lovely bath" />
      <h5 class="feature__title">View ALL</h5>
    </div>
  </div>
</div>
