<div class="item">
  <div class="img__holder">
    <img #img [src]="collection?.attribute_data?.thumbnail"
         (error)="img.src = 'assets/images/noimage_v2.png'"/>
  </div>
  <div class="content">
    <h5 class="title">{{collection?.attribute_data?.name}}</h5>
    <p class="info">{{(collection?.attribute_data?.description || "") | removeHtml}}</p>
  </div>
  <a routerLink=""></a>
</div>
