import {Component, inject} from '@angular/core';
import {SigninOptionComponent} from "../signin-option/signin-option.component";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ApiConstant} from "../../shared/constants";
import {LoginForm, LoginResponse} from "../../shared/model";
import {ValidationMessageComponent} from "../validation-message/validation-message.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    SigninOptionComponent,
    ReactiveFormsModule,
    ValidationMessageComponent,
    RouterLink
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseComponentService{
  private formBuilder: FormBuilder = inject(FormBuilder);
  protected loginForm: FormGroup<{email: FormControl<string | null>, password: FormControl<string | null>}>;
  protected submitFlag: boolean = false;
  constructor() {
    super();
    this.loginForm =  this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
      password: ['', [Validators.required]]
    });
  }
  ngOnInit() {
    this.initForm();

  }

  private initForm() {

  }

  protected login() {
    this.submitFlag = true;

    if(this.loginForm.invalid) {
      return
    }
    this.loader.show();
    this.subscriptionArray = this.request
      .postRequest<LoginForm, LoginResponse>(ApiConstant.AUTH+'/' + ApiConstant.LOGIN,this.loginForm.getRawValue()
    ).subscribe({
      next: (res) => {
        this.loader.hide();
        if(res) {
          this.auth.signIn(res.data.token, res.data.payload)
          this.router.navigate(['account'])
          this.submitFlag = false;
        }
      }
    })
  }


}
