import {inject, Injectable} from '@angular/core';
import {Collection} from "../../../model";
import {GlobalValueService} from "../../global-value-service/global-value.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  private globalValue: GlobalValueService = inject(GlobalValueService);
  private router: Router = inject(Router);

  constructor() { }


  public buildCollectionTree(collections: Collection[]): Collection[] {
    // Sort by left value to ensure proper nesting
    const sortedCollections = collections.sort((a, b) => a._lft - b._lft);

    // Create a map to quickly access collections by ID
    const collectionMap = new Map<number, Collection>();

    // Initialize root-level collections
    const rootCollections: Collection[] = [];

    sortedCollections.forEach(collection => {
      // Add children array and level
      collection.children = [];
      collection.level = this.calculateLevel(sortedCollections, collection);

      // Store in map for quick access
      collectionMap.set(collection.id, collection);

      // If no parent, it's a root collection
      if (collection.parent_id === null) {
        rootCollections.push(collection);
      }
    });

    // Build hierarchy
    sortedCollections.forEach(collection => {
      if (collection.parent_id !== null) {
        const parent = collectionMap.get(collection.parent_id);
        if (parent) {
          parent.children!.push(collection);
        }
      }
    });

    return rootCollections;
  }

  // Calculate the level of a collection in the hierarchy
  private calculateLevel(collections: Collection[], targetCollection: Collection): number {
    let level = 0;
    let currentParentId = targetCollection.parent_id;

    while (currentParentId !== null) {
      const parentCollection = collections.find(c => c.id === currentParentId);
      if (!parentCollection) break;

      level++;
      currentParentId = parentCollection.parent_id;
    }

    return level;
  }

  // Filter collections by group ID
  public filterCollectionsByGroup(collections: Collection[], groupId: number): Collection[] {
    return collections.filter(collection =>
      collection.collection_group_id === groupId
    );
  }

  // Flatten the tree with indentation for display
  public flattenCollectionTree(collections: Collection[]): Collection[] {
    const flattened: Collection[] = [];

    const flattenRecursive = (cols: Collection[], currentLevel = 0) => {
      cols.forEach(collection => {
        const flattenedCollection = {
          ...collection,
          level: currentLevel
        };

        flattened.push(flattenedCollection);

        if (collection.children && collection.children.length) {
          flattenRecursive(collection.children, currentLevel + 1);
        }
      });
    };

    flattenRecursive(collections);
    return flattened;
  }

}
