<div class="my__details-container account__flex__container">
  <div class="head__component">
    <h2 class="title">ADDRESS BOOK</h2>
    <a routerLink="/account/address/add" class="btn" >ADD NEW ADDRESS</a>

  </div>
  <div class="card">
    <div class="location__container">
      <address>
        <p class="name"> John Doe</p>
        <p class="location"> Gabahal, Patan, Lalitpur</p>
        <p class="number"> 9841232468</p>
        <p class="near-location"> (Passageway next to Labim Mall)</p>
        <p class="province"> Bagmati Province</p>
        <p class="district"> Lalitpur</p>
      </address>
      <div class="btn__holder">
        <button>EDIT</button>
        <button>DELETE</button>
      </div>
    </div>
    <div class="default__info">
      <p> This is your default delivery address</p>
      <p> This is your default billing address</p>
    </div>
  </div>
  <div class="card">
    <div class="location__container">
      <address>
        <p class="name"> John Doe</p>
        <p class="location"> Gabahal, Patan, Lalitpur</p>
        <p class="number"> 9841232468</p>
        <p class="near-location"> (Passageway next to Labim Mall)</p>
        <p class="province"> Bagmati Province</p>
        <p class="district"> Lalitpur</p>
      </address>
      <div class="btn__holder">
        <button>EDIT</button>
        <button>DELETE</button>
      </div>
    </div>
    <div class="default__info">
      <div class="checkbox-custom">
        <div class="item">
          <input type="checkbox" id="delivery" />
        </div>
        <div class="item">
          <label for="delivery">Set as default delivery address</label>
        </div>

      </div>
      <div class="checkbox-custom">
        <div class="item">
          <input type="checkbox" id="billing" />
        </div>
        <div class="item">
          <label for="billing">Set as default billing address</label>
        </div>

      </div>
    </div>
  </div>
</div>
