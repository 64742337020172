<div class="my__details-container account__flex__container">
  <div class="head__component">
    <h2 class="title">CREATE A RETURN</h2>
    <p>Select the items you want to return and tell us why</p>
  </div>
  <p class="order__pagination">Displaying 10 of 13 orders</p>
  <div class="card">
    <h3 class="title">ITEMS FROM THIS ORDER</h3>
    <hr>
    <div class="return__item">
      <div class="item__img">
        <img src="../../../assets/media/item__placeholder.png" alt="">
      </div>
      <div class="item__info">
        <p class="item__desc">New Look muscle fit poplin shirt in black</p>
        <div class="item__variants">
          <div><span>BLACK</span> | <span>XS</span></div>
          <div>Qty <span>1</span></div>
        </div>
        <div class="return__options">
          <div class="checkbox-custom">
            <div class="item">
              <input type="checkbox" id="check" />
            </div>
            <div class="item">
              <label for="check">Select to return</label>
            </div>

          </div>
          <div class="reasons">
            <h3>REASON FOR RETURN:</h3>
            <div class="custom__select">
              <select>
                <option value="">Changed my mind</option>
                <option [value]="">Optional - max 500 characters</option>
              </select>
            </div>
            <h3>ADDITIONAL INFORMATION</h3>
            <div class="custom__select">
              <select>
                <option value="">Optional - max 500 characters</option>
                <option [value]="">Changed my mind</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="card">
    <h3 class="title">RETURN METHOD</h3>
    <hr>
    <h4 class="sub__title">FIND A DROP-OFF POINT</h4>
    <div class="search__container">
      <input type="text" placeholder="Search by postcode, street or address">
      <input  type="submit" value="SEARCH" />
    </div>
  </div>
  <div class="card">
    <h3 class="title">TOTAL</h3>
    <hr>
    <div class="policy">
      <h4 class="sub__title">ITEMS TO RETURN:</h4>
      <p>Check out our <a href="#">returns policy</a> for more info.</p>
    </div>
  </div>
  <input  type="submit" value="PLACE RETURN"  class="submit__btn"/>
</div>
