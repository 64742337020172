<!-- <div class="banner__app">
  <div class="container-sm">
    <div class="banner__container">
      <span class="name">THE Aura APP</span>
      <h4 class="title">
        Notifications on your orders and returns? <br />
        App-solutely!
      </h4>
      <a routerLink="" class="btn">Coming Soon</a>
    </div>
  </div>
</div> -->
<div class="banner__app-video">
  <video src="../../../assets/media/banner__video.mp4" muted autoplay loop playsInline></video>
  <div class="container-sm">
    <div class="banner__container">
      <span class="name">THE Aura APP</span>
      <h4 class="title">
        Notifications on your orders and returns? <br />
        App-solutely!
      </h4>
      <a routerLink="" class="btn">Coming Soon</a>
    </div>
  </div>
</div>
