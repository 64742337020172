import {Component, inject, Input} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ProductModel} from "../../shared/model";
import {TitleCasePipe} from "@angular/common";
import {RemoveHtmlPipe} from "../../shared/Pipe/remove-html.pipe";

@Component({
  selector: 'app-product-cta',
  standalone: true,
  imports: [
    RouterLink,
    TitleCasePipe,
    RemoveHtmlPipe
  ],
  templateUrl: './product-cta.component.html',
  styleUrl: './product-cta.component.scss'
})
export class ProductCtaComponent extends BaseComponentService {
  @Input('product') product: ProductModel | null = null;

   ngOnInit() {
  }

  public navigate(id: number  | null) {
     this.router.navigate(['/','detail',id])
  }

}
