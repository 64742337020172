import {Component, Input} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ProductModel} from "../../shared/model";
import {RouterLink} from "@angular/router";
import {TitleCasePipe} from "@angular/common";

@Component({
  selector: 'app-one-product',
  standalone: true,
  imports: [
    RouterLink,
    TitleCasePipe
  ],
  templateUrl: './one-product.component.html',
  styleUrl: './one-product.component.scss'
})
export class OneProductComponent extends BaseComponentService{
  @Input('productDetail') product: ProductModel | null = null


   ngOnInit() {
  }
}
