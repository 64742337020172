import {inject, Injectable, OnDestroy, OnInit, PLATFORM_ID, TransferState} from '@angular/core';
import {Subscription} from "rxjs";
import {BaseApiService} from "../services/base-request/base-api.service";
import {AuthenticationService} from "../services/authentication-service/authentication.service";
import {LoaderService} from "../services/loader-service/loader.service";
import {GlobalValueService} from "../services/global-value-service/global-value.service";
import {Router} from "@angular/router";
import {FilterService} from "../services/filter-service/filter.service";

@Injectable()
export abstract class BaseComponentService implements OnInit, OnDestroy{
  private subscription: Subscription[] = [];
  protected platformId = inject(PLATFORM_ID);
  protected request = inject(BaseApiService);
  protected auth: AuthenticationService = inject(AuthenticationService);
  protected loader: LoaderService = inject(LoaderService);
  protected globalValue: GlobalValueService = inject(GlobalValueService);
  protected transferState: TransferState = inject(TransferState);
  protected router: Router = inject(Router);
  protected filterService:FilterService =inject(FilterService);

  protected set subscriptionArray(subscription: Subscription) {
    this.subscription.push(subscription);
  }
  constructor() { }

  abstract ngOnInit(): void;

  protected dispose(){
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
  this.dispose();
  }
}
