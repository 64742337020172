import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
  name: 'removeHtml',
  standalone: true
})
export class RemoveHtmlPipe implements PipeTransform{
   transform(value: string = ""): string {
     if(!value && !value.trim()) return '';
     let myHtml = new DOMParser().parseFromString(value, 'text/html');
     return myHtml.body.textContent || "";
   }
}
