@if (submitted && control.invalid && control.getError('required')) {
    <span>Kindly fill in the required fields</span>
}
@if (submitted && control.invalid && control.getError('email')) {
    <span>Incorrect email format "example&#64;example.com"</span>
}
@if (submitted && control.invalid && control.getError('min')) {
  <span>Must be 8   or more characters</span>
}


