<!-- <h1>Hello<h1>
<router-outlet /> -->


<main>
  <div class="account">
    <div class="container-xs">
      <h2 class="section__heading">My Account</h2>
      <div class="account__container">

        <div class="account__menu">
          <div class="profile__container">
            <div class="profile__image">
              <p>{{userPrefix | uppercase}}</p>
            </div>
            <div class="profile__name">
              Hi,
              <h4 class="name">{{userDetail?.name ? userDetail?.name : userDetail?.email | titlecase}}</h4>
            </div>
          </div>
          <div class="link__item" >
            <a routerLink="/account/" >Account overview</a>
          </div>
          <div class="link__item">
            <a routerLink="/account/orders">My Orders</a>
            <a routerLink="/account/returns">My Returns</a>
          </div>
          <div class="link__item">
            <a routerLink="/account/my-details">My Details</a>
            <a routerLink="/account/change-password">Change Password</a>
            <a routerLink="/account/address">Address Book</a>
            <a routerLink="/account/asd">Payment Methods</a>
            <a routerLink="/account/asd">Social Accounts</a>
          </div>
          <div class="link__item">
            <a routerLink="/account/asd">Need help?</a>
            <a routerLink="/account/asd">How do I make a return?</a>
          </div>
          <div class="link__item">
            <a (click)="signOut()">Sign out</a>
          </div>
        </div>
        <div class="account__content">
          <router-outlet />
        </div>

      </div>
    </div>
    <div class="account__footer">
      <div class="container-xs">
        <div class="left">
          <a routerLink="/account/" >CELAVI Homepage</a>
          <a routerLink="/account/" >Terms & Conditions</a>
          <a routerLink="/account/" >Privacy Policy</a>
        </div>
        <div class="right">
          <p>© CELAVI 2024</p>
        </div>
      </div>
    </div>
  </div>
</main>
