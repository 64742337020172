import { Component } from '@angular/core';

@Component({
  selector: 'app-account-address-add',
  standalone: true,
  imports: [],
  templateUrl: './account-address-add.component.html',
  styleUrl: './account-address-add.component.scss'
})
export class AccountAddressAddComponent {

}
