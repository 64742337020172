import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Signal, viewChild} from '@angular/core';
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {ApiConstant} from "../../shared/constants";
import {ProductDetailsAttributes, SingleProductModel} from "../../shared/model";
import {AssociationModel} from "../../shared/model/product.model";
import {SwiperContainer} from "swiper/swiper-element";

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductDetailComponent extends BaseComponentService{
  private route: ActivatedRoute = inject(ActivatedRoute);
  protected products: SingleProductModel | null = null;
  protected productOption:ProductDetailsAttributes[]  = [];
  protected productDetail:ProductDetailsAttributes[]  = [];
  public crossSellProduct: AssociationModel[] = [];
  public upSellProduct: AssociationModel[] = [];

  private exceptDetailArray: Array<string> = ['name'];
  private elementRef: ElementRef = inject(ElementRef);
  private swiper: Signal<ElementRef<SwiperContainer> | undefined> = viewChild<ElementRef<SwiperContainer>>('swiper');




  override ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.getProductIdFromParams();
    }
  }

  private resetAll() {
   this.productOption = [];
   this.productDetail = [];
   this.crossSellProduct = [];
   this.upSellProduct = [];
   this.elementRef.nativeElement.scrollTop = 0;
  }
  private getProductIdFromParams() {
    this.subscriptionArray = this.route.params.subscribe({
      next: (params) => {
        this.resetAll();
        this.getProductById(params['id']);
      }
    })
  }


  private getProductById(id: Number) {
    this.loader.show();
    this.subscriptionArray = this.request.getRequest<SingleProductModel>(`${ApiConstant.PRODUCTS}/${id}`,{
      ...this.request.defaultConfiguration,
      auth: false,
      domain: this.request.secondBaseUrl
    }).subscribe({
      next: (res: any) => {
        this.loader.hide();
        if(res){
          this.products = res.data.payload;
          this.setProductDetails();
        }
      }
    })
  }

  private setProductDetails() {
    this.productOption = Object.keys(this.products?.options || {}).map((key) => {
      return {
        name: key,
        array: this.products?.options[key]
      }
    });
    Object.keys(this.products?.attribute_data || {}).forEach((key) => {
      if(!this.exceptDetailArray.includes(key)) {
        this.productDetail.push({
          id: key,
          name: key.split("-").join(' '),
          description: this.products?.attribute_data[key],
          active: false
        })
      }
    })
    this.productDetail.push({
      id: this.products?.brand.id,
      name: 'brand',
      description: `<p>${this.products?.brand.name}</p>${this.products?.brand.description}`,
      active: false
    })
    this.productDetail[0].active = true;

    this.products?.associations.forEach((data) => {
      if(ApiConstant.UP_SELLING.toLowerCase() == data.type.toLowerCase()) {
        this.upSellProduct.push(data);
      } else {
        this.crossSellProduct.push(data);
      }
    })
  }

  openDetailDropdown(id: string | null = null){
    this.productDetail.forEach((data) => {
      if(data.id === id) {
        data.active = !data.active;
      } else {
        data.active = false;
      }
    })
  }

}
