<div class="filter__container">
  <h2 class="page__title">{{globalValue.navOption() | titlecase}} Items</h2>
  <div class="unique__container">
<!--    <ul class="filter__tag__list">-->
<!--        <li><a href="#">Dresses</a></li>-->
<!--        <li><a href="#">Tops</a></li>-->
<!--        <li><a href="#">Shoes</a></li>-->
<!--        <li><a href="#">Trousers & Leggings </a></li>-->
<!--        <li><a href="#">Jackets & Coats</a></li>-->
<!--        <li><a href="#">Jumpsuits & Playsuits</a></li>-->
<!--        <li><a href="#">Jeans</a></li>-->
<!--        <li><a href="#">Suits & Blazers</a></li>-->
<!--        <li><a href="#">Activewear</a></li>-->
<!--    </ul>-->
</div>
<div class="filter">
  <div class="container">
      <div class="filter__container">
        @for (filter of filterListFromApi.key; track filter) {
          <div class="custom__select__container" appOutsideClick toggleClass="active"
               [ngClass]="{'active':filterListFromApi.dict[filter].active,'input__selected': filterListFromApi.dict[filter].selectCount > 0 }">
            <div class="custom__select" (click)="openFilter(filter)">
              <span>{{filter | titlecase}}</span>
              <div class="arrow">
                <i class="icons icon-chev-down"></i>
              </div>
            </div>
            <div class="options__container">
              <div class="options__header">
                <div class="selected__options">
                  <div class="content">
                    <p>{{filterListFromApi.dict[filter].selectCount}} Selected</p>
                  </div>
                  @if(filterListFromApi.dict[filter].selectCount > 0) {
                    <button (click)="clearSpecificFilter(filter)">
                      <i class="icon icon-close"></i> Clear All
                    </button>
                  } @else {
                    <button (click)="selectAllCategory(filter)">
                      <i class="icon icon-check"></i> All
                    </button>
                  }

                </div>
                <div class="search__container">
                  <input [(ngModel)]="filterListFromApi.dict[filter].searchValue" type="search" class="custom__search" placeholder="Search">
                </div>
              </div>
              <ul>
                @for (filterOption of filterListFromApi.dict[filter].option | listFilter:filterListFromApi.dict[filter].searchValue:'name'; track filterOption.name; let i = $index) {
                  <li (click)="onChange(filter, i)">
<!--                    <input type="checkbox" [id]="'category'+i" [name]="filterOption.name" [value]="filterOption.selected">-->
                  <label  [ngClass]="{'selected': filterOption.selected}"
                         [for]="'category'+i">{{filterOption?.name}}
                    @if(filterOption.count) {
                      <span class="count">({{filterOption?.count}})</span>
                    }
                  </label>
                  </li>
                }
              </ul>
            </div>
          </div>

        }
<!--          <div class="custom__select__container">-->
<!--              <div class="custom__select">-->
<!--                  <span>Price Range</span>-->
<!--                  <div class="arrow">-->
<!--                    <i class="icons icon-chev-down"></i>-->
<!--                  </div>-->
<!--              </div>-->
<!--              <div class="options__container">-->
<!--                  <div class="options__header">-->
<!--                      <div class="selected__options">-->
<!--                          <div class="content">-->
<!--                              <p>Price Range Selected</p>-->
<!--                              <p class="list">£5 - £170</p>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--                  <ul>-->
<!--                      <div class="custom__range">-->
<!--                          <div class="price__range">-->
<!--                              <span>£5</span> <span>£170</span>-->
<!--                          </div>-->
<!--                          <div class="slider__track">-->
<!--                              <input type="range" min="0" max="100" value="0" id="slider-1">-->
<!--                              <input type="range" min="0" max="100" value="100" id="slider-2">-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </ul>-->
<!--              </div>-->
<!--          </div>-->
      </div>
  </div>
</div>

<div class="container">
  <div class="products__container">
   @for(pro of productList; track pro) {
      <app-one-product [productDetail]="pro" />
   }
  </div>
</div>

 <div class="container">
   <div></div>

 </div>



  </div>
