<div class="filter-container">
  <h2 class="page-title">Saved Items</h2>
  <div class="container">
    <div class="products-container">
      <!-- Item 1 -->
      <div class="item">
        <div class="normal">
          <div class="img-holder">
            <span class="discountRate">-29%</span>
            <span class="stock-option">OUT OF STOCK</span>
            <span class="sale-process">Selling fast</span>
            <span class="remove">🗑</span>
            <img src="assets/media/product-img.png" alt="Product Image" />
          </div>
          <div class="content">
            <p class="info">CELAVI DESIGN Wide Fit Region suede mid-heel boots in black</p>
            <div class="price discount">
              <span>£79.99</span> <span>£79.99</span>
            </div>
          </div>
          <a href="/detail">View Details</a>
        </div>
        <div class="extra">
          <div class="selected-color">BLACK - Out of Stock</div>
          <select>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
          </select>
          <div class="buttons">
            <button>See Similar</button>
            <button class="notify">NOTIFY ME</button>
          </div>
        </div>
      </div>

      <!-- Item 2 -->
      <div class="item">
        <div class="normal">
          <div class="img-holder">
            <span class="discountRate">-29%</span>
            <span class="color-option">More Colours</span>
            <span class="sale-process">Selling fast</span>
            <span class="remove">🗑</span>
            <img src="assets/media/product-img.png" alt="Product Image" />
          </div>
          <div class="content">
            <p class="info">CELAVI DESIGN Wide Fit Region suede mid-heel boots in black</p>
            <div class="price discount">
              <span>£79.99</span> <span>£79.99</span>
            </div>
          </div>
          <a href="/detail">View Details</a>
        </div>
        <div class="extra">
          <div class="selected-color">BLACK - Out of Stock</div>
          <select>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
          </select>
          <div class="buttons">
            <button>Move to Bag</button>
          </div>
        </div>
      </div>

      <!-- Item 3 -->
      <div class="item">
        <div class="normal">
          <div class="img-holder">
            <span class="remove">🗑</span>
            <img src="assets/media/product-img.png" alt="Product Image" />
          </div>
          <div class="content">
            <p class="info">CELAVI DESIGN Wide Fit Region suede mid-heel boots in black</p>
            <div class="price discount">
              <span>£79.99</span> <span>£79.99</span>
            </div>
          </div>
          <a href="/detail">View Details</a>
        </div>
        <div class="extra">
          <div class="selected-color">BLACK - Out of Stock</div>
          <select>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
            <option value="">XS - UK 6 - Out of stock</option>
          </select>
          <div class="buttons">
            <button>Move to Bag</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
