import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-user-term',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './user-term.component.html',
  styleUrl: './user-term.component.scss'
})
export class UserTermComponent {

}
