import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
  standalone: true
})
export class OutsideClickDirective {
  @Input() triggerElement?: HTMLElement;
  @Input() toggleClass = '';
  @Output() outsideClick = new EventEmitter<void>();

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  private isClickOutside(event: MouseEvent, targetElement: HTMLElement = this.elementRef.nativeElement): boolean {
    const rect = targetElement.getBoundingClientRect();
    const isInBounds =
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom;

    return !isInBounds;
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(event: MouseEvent): void {
    const targetElement = this.triggerElement || this.elementRef.nativeElement;

    // Check if click is outside both the current element and the trigger element
    const isOutsideCurrentElement = this.isClickOutside(event);
    const isOutsideTriggerElement = this.triggerElement
      ? this.isClickOutside(event, this.triggerElement)
      : true;

    if (isOutsideCurrentElement && isOutsideTriggerElement) {
      if (this.toggleClass) {
        targetElement.classList.remove(this.toggleClass);
      }
      this.outsideClick.emit();
    }
  }
}
