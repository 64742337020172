import { Component } from '@angular/core';

@Component({
  selector: 'app-account-welcome',
  standalone: true,
  imports: [],
  templateUrl: './account-welcome.component.html',
  styleUrl: './account-welcome.component.scss'
})
export class AccountWelcomeComponent {

}
