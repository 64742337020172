export class ApiConstant {
  public static AUTH = 'auth'
  public static LOGIN = 'login';
  public static GOOGLE_REDIRECT = 'google/redirect';
  public static COLLECTION = 'collections';
  public static REGISTER = 'register';
  public static PRODUCTS = 'products';
  public static BRANDS = 'brands';
  public static GOOGLE = 'google';
  public static COLLECTION_GROUP = 'collection-groups';
  public static LOCALSTORAGE = {
    user: 'USER',
    token: 'TOKEN',
    gender: 'GENDER'
  };
  public static UP_SELLING = 'up-sell';
  public static CROSS_SELLING = 'cross-sell';
}
