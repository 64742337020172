<div class="my__details-container">
  <div class="head__component">
    <h2 class="title">Add Address</h2>
    <p>Feel free to edit any of your details below so your CELAVI account is totally up to date. (* Indicates a required
      field).</p>

  </div>
  <form class="custom__form">

    <div class="input__box">
      <label>Full Name*:</label>
      <input formControlName="full_name" type="text" />
    </div>
    <div class="input__box">
      <label>Address*:</label>
      <input formControlName="address" type="text" />
    </div>
    <div class="input__box">
      <label>Mobile Number*:</label>
      <input formControlName="number" type="number" />
    </div>
    <div class="input__box">
      <label>Landmark (Optional):</label>
      <input formControlName="Landmark" type="text" />
    </div>
    <div class="input__box">
      <label>PROVINCE:</label>
      <div class="custom__select">
        <select>
          <option value="">Bagmati</option>
          <option [value]="">Bagmati</option>
        </select>
      </div>
    </div>
    <div class="input__box">
      <label>City:</label>
      <div class="custom__select">
        <select>
          <option value="">Please Select</option>
          <option [value]="">Kathmandu</option>
        </select>
      </div>
    </div>

    <div class="input__box">
      <label>Select label for effective delivery</label>
      <div class="radio">
        <div class="radio__btn">
          <input formControlName="interest" type="radio" id="home" name="interest" value="home" />
          <label for="home">Home</label>
        </div>
        <div class="radio__btn">
          <input formControlName="interest" type="radio" id="office" name="interest" value="office" />
          <label for="office">Office</label>
        </div>
      </div>
    </div>
    <input  type="submit" value="Save Changes" />
  </form>
</div>
