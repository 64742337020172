import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {UserTermComponent} from "../../components";

@Component({
  selector: 'app-user-page',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    UserTermComponent,
    RouterLinkActive
  ],
  templateUrl: './user-page.component.html',
  styleUrl: './user-page.component.scss'
})
export class UserPageComponent {

}
