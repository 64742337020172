<div class="banner__two">
  <div class="bg__art">
    <img src="assets/media/banner__image-new.png" width="100%" height="160" alt="A lovely bath" />
  </div>
  <div class="container-sm">
    <div class="banner__container">
      <h4 class="title">
        Let Your Aura Speak Through Fashion!
        Curated Collections Designed to Reflect Your Unique Style!
      </h4>
      <span class="condition">
        Discover exclusive brands and styles at Aura Fashion Nepal's webstore!
            </span>
    </div>
  </div>
</div>

