import { Component } from '@angular/core';

@Component({
  selector: 'app-oops',
  standalone: true,
  imports: [],
  templateUrl: './oops.component.html',
  styleUrl: './oops.component.scss'
})
export class OopsComponent {

}
