import {Component, effect} from '@angular/core';
import {RouterOutlet, RouterLink} from "@angular/router";
import {BaseComponentService} from "../../shared/base-component/base-component.service";
import {UserType} from "../../shared/model";
import {isPlatformBrowser, TitleCasePipe, UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-account-page',
  standalone: true,
  imports: [
    RouterOutlet, RouterLink, TitleCasePipe, UpperCasePipe
  ],
  templateUrl: './account-page.component.html',
  styleUrl: './account-page.component.scss'
})
export class AccountPageComponent extends BaseComponentService{
  protected userDetail: UserType | null = null;
  protected userPrefix: string | null = null;

constructor() {
  super();
  effect(() => {
    this.userDetail = this.auth.user();
    this.userPrefix = this.auth.user()?.name?.substring(0,2) || null;
  })
}
   ngOnInit() {
     if(isPlatformBrowser(this.platformId)){
       this.userDetail = this.auth.user();
       this.userPrefix = this.userDetail
         ?.name
         ?.split(" ")
         .map(data => data.substring(0,1))
         .join('') || null;

     }
  }


  public signOut() {
  this.auth.signOut();

   }


}
