<div class="my__details-container">
  <div class="head__component">
    <h2 class="title">My details</h2>
    <p>
      Feel free to edit any of your details below so your CELAVI account is totally up to date. (* Indicates a required
      field).
    </p>
  </div>
  <form class="custom__form" [formGroup]="registerForm">
    <div class="input__box">
      <label>First Name*:</label>
      <input formControlName="first_name" type="text" />
      <app-validation-message [controlForError]="registerForm.controls.first_name" [submitted]="submitFlag" />
    </div>
    <div class="input__box">
      <label>Last Name*:</label>
      <input formControlName="last_name" type="text" />
      <app-validation-message [controlForError]="registerForm.controls.last_name" [submitted]="submitFlag" />
    </div>
    <div class="input__box">
      <label>Email Address*:</label>
      <input formControlName="email" type="text" />
      <app-validation-message [controlForError]="registerForm.controls.email" [submitted]="submitFlag" />
    </div>
    <div class="input__box" formGroupName="dob">
      <label>Date of birth:</label>
      <div class="birthday">
        <div class="custom__select">
          <select formControlName="day">
            <option value="">DD</option>
             @for (day of date.day(this.getRawValue.dob.year || '2023', this.getRawValue.dob.month || '1');track day;let i = $index) {
              <option >
                 {{day}}
              </option>
             }
          </select>
        </div>
        <div class="custom__select">
          <select formControlName="month">
            <option value="">Month</option>
             @for (mon of date.month;track mon;let i = $index) {
              <option >
                 {{mon}}
              </option>
             }
          </select>
        </div>
        <div class="custom__select">
          <select formControlName="year">
            <option value="">YYYY</option>
             @for (ye of date.year;track ye;let i = $index) {
              <option >
                 {{ye}}
              </option>
             }
          </select>
        </div>
      </div>
    </div>
    <div class="input__box">
      <label>MOSTLY INTERESTED IN:</label>
      <div class="radio">
        <div class="radio__btn">
          <input formControlName="interest" type="radio" id="womenswear" name="interest" value="Womenswear" />
          <label for="womenswear">Womenswear</label>
        </div>
        <div class="radio__btn">
          <input formControlName="interest" type="radio" id="menswear" name="interest" value="Menswear" />
          <label for="menswear">Menswear</label>
        </div>
        <app-validation-message [controlForError]="registerForm.controls.interest" [submitted]="submitFlag" />
      </div>
    </div>
    <input  type="submit" (click)="submit()" value="Save Changes" />
  </form>
</div>
