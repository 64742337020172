<div class="breadcrumb">
  <div class="container">
    <ul class="list">
      <li><a routerLink="/">Home</a> </li><span class="arrow">
<!--      <FaAngleRightSolid />-->
    </span>
      <li><a routerLink="/"></a> </li><span class="arrow">
<!--      <FaAngleRightSolid />-->
    </span>
      <li>Sale</li>
    </ul>
  </div>
</div>
