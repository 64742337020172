import { Component } from '@angular/core';
import {RouterOutlet, RouterLink} from "@angular/router";
@Component({
  selector: 'app-account-address',
  standalone: true,
  imports: [
     RouterLink
  ],
  templateUrl: './account-address.component.html',
  styleUrl: './account-address.component.scss'
})
export class AccountAddressComponent {

}
