import {computed, Injectable, signal, WritableSignal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading: WritableSignal<Array<boolean>> = signal([]);
  public isLoading = computed(() => {
    return this.loading().length > 0;
  })
  constructor() { }

  public show() {
    let load = this.loading();
    this.loading.set([...load,true]);
  }

  public hide() {
    setTimeout(() => {
      this.loading
        .set(this.loading()
          .filter((a,i) => i !== (this.loading().length -1)));
    },1000)

  }


}
