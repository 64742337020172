import {computed, effect, inject, Injectable, OnDestroy, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {ApiConstant, COLLECTION} from "../../constants";
import {isPlatformBrowser} from "@angular/common";
import {compareSegments} from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/segment_marker";
import {CustomProductFilter} from "../../model/products-filter.model";

@Injectable({
  providedIn: 'root'
})
export class GlobalValueService {
  public navOption: WritableSignal<string> = signal('');
  public searchValue: WritableSignal<string> = signal("");
  public platformId = inject(PLATFORM_ID);
  constructor() {
      effect(() => {
        const value = this.navOption();
        if(isPlatformBrowser(this.platformId) && value) {
         this.saveToLocalStorage();
        }
      })
    if(isPlatformBrowser(this.platformId)) {
      this.getFromLocalStorage();
    }
  }

  ngOnInit() {

  }

  private getFromLocalStorage() {
    try {
     const gender = localStorage.getItem(ApiConstant.LOCALSTORAGE.gender);
     this.navOption.set(gender || COLLECTION.men);
    } catch  {
      this.navOption.set(COLLECTION.men);
    }

  }

  private saveToLocalStorage() {
    localStorage.setItem(ApiConstant.LOCALSTORAGE.gender, this.navOption());
  }



}
