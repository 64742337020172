import {environment} from "../../../../environments/environment";
import {ApiConstant} from "../../constants/api.constant";
import crypto from 'crypto-js';

export class SecurityService {

  private static get getCryptoJs() {
    return crypto;
  }

  private static get getSecret() {
    return environment.PUBLIC_SECRET_KEY;
  }

  private static decodeString(data: string) {
    if (!data) return null;
    try {
      const bytes = this.getCryptoJs.AES.decrypt(data, this.getSecret);
      return JSON.parse(bytes.toString(this.getCryptoJs.enc.Utf8));
    } catch (e) {
      SecurityService.clearLocalStorage();
    }
  }

  private static encodeString(data: any) {
    if (data) return this.getCryptoJs.AES.encrypt(JSON.stringify(data), this.getSecret).toString();
    else return null;
  }

  /**
   * Encodes and saves the data to localstorage
   * @param key
   * @param value
   */
  public static saveToLocalStorage(key: string, value: any) {
    localStorage.setItem(key, SecurityService.encodeString(value) || "");
  }

  /**
   * Decodes and loads the data to localstorage
   * @param key
   */
  public static getFromLocalStorage(key: string) {
    return SecurityService.decodeString(localStorage.getItem(key) || "");
  }

  /**
   * Removes all attendance items from localstorage
   */
  public static clearLocalStorage() {
    Object.values(ApiConstant.LOCALSTORAGE).forEach(value => {
      localStorage.removeItem(value);
    })
  }
}
