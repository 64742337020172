<div class="brands">
  <div class="container">
    <h2 class="section__heading">
      TRENDING BRANDS
    </h2>
    <swiper-container [breakpoints]="breakpoints"
                      autoplay-delay="2500"
                      autoplay-disable-on-interaction="false"
                      loop="true"
                      slides-per-view="1"
                      space-between="30" speed="500">
      @for (brand of brandsArray; track brandsArray) {
        <swiper-slide>
          <div class="card">
            <img [src]="brand.logo"
                 width="193" height="193"
                 layout="fullWidth" alt="A lovely bath" />
          </div>
        </swiper-slide>
      }
    </swiper-container>
  </div>
</div>
